import React, { Component } from "react";
import PropTypes from "prop-types";
import Boulanger from "../images/clients/boulanger.svg";
import Smav from "../images/clients/smav.png";
import Philarmonie from "../images/clients/philarmonie.svg";
import Mutex from "../images/clients/mutex.png";
import Nievre from "../images/clients/nievre.png";
export default class Logos extends Component {
  render() {
    return (
      <div className="bg-gray-900">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base leading-6 font-semibold text-gray-600 tracking-wider">
            {this.props.title && this.props.title} Trusted by a lot of customers
          </p>
          <div className="mt-6 grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8 flex flex-wrap items-center justify-center">
            <div
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 partner-logo"
              // key={i}
            >
              <img
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-h-12 "
                src={Boulanger}
              />
            </div>
            <div
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 partner-logo"
              // key={i}
            >
              <img
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-h-12 "
                src={Nievre}
              />
            </div>
            <div
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 partner-logo"
              // key={i}
            >
              <img
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-h-12 "
                src={Philarmonie}
              />
            </div>
            <div
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 partner-logo"
              // key={i}
            >
              <img
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-h-12 "
                src={Smav}
              />
            </div>
            <div
              className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 partner-logo"
              // key={i}
            >
              <img
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-h-12 "
                src={Mutex}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
