import React, { useState } from "react";
import axios from "axios";
import Check from "../images/check.svg";
import useHubspotForm from '@aaronhayes/react-use-hubspot-form';

export default () => {

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '8056686',
    formId: '036fc0aa-9865-4e6b-960d-0644acf125b4',
    target: '#hb-form'
});
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, message: null },
  });
  const [inputs, setInputs] = useState({
    email: "",
    message: "",
    events: null,
    actus: null,
  });
  const handleServerResponse = (success, message) => {
    if (success) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, message: message },
      });
      setInputs({
        email: "",
        message: "",
        events: false,
        actus: false,
      });
    } else {
      setStatus({
        info: { error: true, message: message },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, message: null },
    });
  };
  const handleOnCheckChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.checked,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, message: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));

    axios({
      method: "POST",
      url: `https://www.zenops.fr/api/sendMail`,
      data: inputs,
      headers: {},
    })
      .then((response) => {
        // console.log("response", response);
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch((error) => {
        // console.log(error, "ERROR");
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
      <div className="">
        <h2
          className="mt-2 text-gray-900 text-3xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500">
          Nous écrire
        </h2>
        <div id="hb-form" className=" mt-4 h-full"></div>
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `hbspt.forms.create({portalId:"8056686",formId:"036fc0aa-9865-4e6b-960d-0644acf125b4",target:"#hb-form"})`,
          }}
        /> */}

        {/* <form
          className=" sm:flex flex-col"
          method="POST"
          onSubmit={handleOnSubmit}
        >
          <input
            className="mt-3 appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring focus:border-blue-300 transition duration-150 ease-in-out"
            id="email"
            type="email"
            name="_replyto"
            placeholder="Entrez votre adresse mail"
            onChange={handleOnChange}
            required
            value={inputs.email}
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500"
          />
          <textarea
            className="mt-3  appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring focus:border-blue-300 transition duration-150 ease-in-out "
            id="message"
            name="message"
            placeholder="Écrivez votre message"
            onChange={handleOnChange}
            required
            value={inputs.message}
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500"
          />
          <p className="mt-4">
            J’accepte de recevoir des : <br />
            <input
              type="checkbox"
              id="events"
              onChange={handleOnCheckChange}
              checked={inputs.events}
            />
            <label htmlFor="events" className="ml-4">
              courriels d’invitation à des évènements{" "}
            </label>
            <br />
            <input
              type="checkbox"
              id="actus"
              onChange={handleOnCheckChange}
              checked={inputs.actus}
            />
            <label className="ml-4" htmlFor="actus">
              courriels d’actualité ZenOps
            </label>
          </p>
          <div className="my-3 mt-3 rounded-md shadow sm:flex-shrink-0 transform hover:-translate-y-1 transition duration-300">
            <button
              disabled={status.submitting}
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-in-out"
              data-sal-duration="500"
              className=" w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transition duration-150 ease-in-out"
            >
              {!status.submitting ? (
                !status.submitted ? (
                  "Envoyez le message!"
                ) : (
                  <img
                    src={Check}
                    className="sent-check"
                    alt="check arrow zenops"
                  />
                )
              ) : (
                "Envoi en cours..."
              )}
            </button>
          </div>
        </form> */}
      </div>
    </div>
  );
};
