import React from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import { Date, Link, RichText } from "prismic-reactjs";
import { divide } from "lodash";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      data-sal="fade"
      style={{ ...style, zIndex: "1" }}
      className="absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-primary-600 hover:text-primary-400 focus:text-primary-400 -mr-6 focus:outline-none focus:ring"
    >
      <span className="block" style={{ transform: "scale(1)" }}>
        &#x279c;
      </span>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      data-sal="fade"
      style={{ ...style, zIndex: "1" }}
      onClick={onClick}
      className="absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-primary-600 hover:text-primary-400 focus:text-primary-400 -ml-6 focus:outline-none focus:ring"
    >
      <span className="block" style={{ transform: "scale(-1)" }}>
        &#x279c;
      </span>
    </button>
  );
}
export default class SimpleSlider extends React.Component {
  render() {
    var settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      cssEase: "ease-in-out",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div
        className="mx-auto pb-0 p-8 sm:p-8 lg:p-24 bg-gray-900 mt-48 sm:mt-56"
        style={{ minHeight: 0, minWidth: "0" }}
      >
        <div
          className="relative rounded-lg block md:flex items-center bg-gray-800 shadow-xl  max-w-screen-lg mx-auto"
          style={{ minHeight: "19rem", marginTop: "-10em" }}
        >
          <div
            className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg"
            style={{ minHeight: "19rem" }}
          >
            {this.props.data.primary.featured_image && (
              <Img
                fluid={this.props.data.primary.featured_image.fluid}
                alt={"carousel image zenops"}
                className="absolute inset-0 w-full h-full object-cover object-center"
              />
            )}
            <div className="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white"></div>
          </div>

          <div className="w-full md:w-3/5 h-full items-center bg-gray-800 rounded-lg text-white">
            <Slider {...settings}>
              {this.props.data.items.map((s, i) => (
                <div key={i}>
                  <div className="p-12 md:pr-24 md:pl-16 md:py-12 flex flex-col justify-center">
                    <div className="mt-2 text-3xl leading-9 font-bold tracking-tight sm:text-2xl mb-4">
                      <RichText render={s.carousel_title.raw} />
                    </div>

                      <span className="text-white">
                        <RichText render={s.carousel_description.raw} />
                      </span>

                    {s.carousel_link.url ? (
                      <a
                        target="_blank"
                        className="flex items-baseline mt-3 text-primary-600 hover:text-primary-900 focus:text-primary-900"
                        href={s.carousel_link.url}
                      >
                        <span>En savoir plus</span>
                        <span className="text-xs ml-1">&#x279c;</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
