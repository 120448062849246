import React, { Component } from "react";
import Slider from "react-slick";

export default class LogosCarousel extends Component {
  render() {
    var settings = {
      slidesToShow: 4,
      centerMode: true,
      infinite: true,
      autoplay: true,
      speed: 5000,
      cssEase: "linear",
      arrows: false,
      draggable: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            dots: false,
            arrows: false,
            centerMode: false,
            slidesToShow: 3,
            speed: 8000,
          },
        },
      ],
    };
    return (
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {this.props.title && this.props.title}
          </p>
          <div className="mt-6 grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8 block flex-wrap items-center justify-center">
            <Slider {...settings}>
              {this.props.items?.map((l, i) => (
                <div
                  className="flex align-center justify-center px-8 "
                  key={i}
                >
                  <img
                    className="h-12 m-w-full m-4"
                    src={l.partner_logo.url}
                    alt={l.partner_logo.alt}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
