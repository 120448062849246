import React, { Component } from "react";
import Img from "gatsby-image";

export default class Testimonial extends Component {
  render() {
    return (
      <div className="py-12 bg-gray-50 bg-white overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="svg-pattern-squares-1"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#svg-pattern-squares-1)" />
          </svg>

          <div className="relative">
            <Img
              className="max-w-50 m-auto"
              fluid={this.props.data.primary.testimonial_logo.fluid}
              alt="logo temoignage"
            />

            <blockquote className="mt-8">
              <div
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-in-out"
                data-sal-duration="500"
                className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900"
              >
                {this.props.data.primary.quote.text}
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <Img
                      className="mx-auto h-10 w-10 rounded-full"
                      fluid={this.props.data.primary.portrait_author.fluid}
                      alt="portrait temoignage"
                      data-sal="slide-up"
                      data-sal-delay="300"
                      data-sal-easing="ease-in-out"
                      data-sal-duration="500"
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div
                      className="text-base leading-6 font-medium text-gray-900"
                      data-sal="slide-up"
                      data-sal-delay="300"
                      data-sal-easing="ease-in-out"
                      data-sal-duration="500"
                    >
                      {this.props.data.primary.name_of_the_author.text}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    );
  }
}
