import React, { Component } from "react";
import La_Defense from "../images/La_Defense.png";
import { Date, Link, RichText } from "prismic-reactjs";

export default class Mission extends Component {
  render() {
    return (
      <>
        <div className="pt-16 overflow-hidden lg:pt-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              />
            </svg>

            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {this.props.story.text}
              </h3>
            </div>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4
                  className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                >
                  {this.props.know_how_title.text}
                </h4>
                <div
                  className="mt-3 text-gray-500 prose"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                >
                  <RichText render={this.props.description_know_how.raw} />
                </div>

                <h4
                  className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9 mt-10 prose"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                >
                  {this.props.philosophy_title.text}
                </h4>
                <div
                  className="mt-3 text-gray-500 prose"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                >
                  <RichText render={this.props.philosophy_description.raw} />
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  className="relative mx-auto"
                  width="490"
                  src={La_Defense}
                  alt="la défense zenops"
                />
              </div>
            </div>
          </div>
          <div className="py-12 bg-gray-100 mt-10">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
              <div className="relative">
                <h3
                  className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 pb-12 mt-10"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                >
                  {this.props.title.text}
                </h3>
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                {this.props.data.map((m, i) => (
                  <div key={i}>
                    <div className="flex items-center justify-center w-16 bg-gray-900 rounded-md text-white p-2 mt-10">
                      <img src={m.mission_icon.url} alt="zenops mission" />
                    </div>
                    <div className="mt-5 prose">
                      <h5
                        className="text-lg font-medium text-gray-900 "
                        data-sal="slide-up"
                        data-sal-delay="300"
                        data-sal-easing="ease-in-out"
                        data-sal-duration="500"
                      >
                        {m.mission_title1.text}
                      </h5>
                      <div
                        className="mt-2 text-base leading-6 text-gray-500 richtext"
                        data-sal="slide-up"
                        data-sal-delay="300"
                        data-sal-easing="ease-in-out"
                        data-sal-duration="500"
                      >
                        <RichText render={m.mission_text.raw} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
